@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: 'DM Sans', sans-serif !important;
    font-feature-settings: 'kern' !important;
    -webkit-font-smoothing: antialiased;
    letter-spacing: -0.5px;
    font-size: 15.5px;
  }
}

input.defaultCheckbox::before {
  content: url(../src/assets/svg/checked.svg);
  color: white;
  opacity: 0;
  height: 16px;
  width: 16px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0px);
}

input:checked.defaultCheckbox::before {
  opacity: 1;
}

.chakra-modal__content-container {
  @apply z-[100];
}

table {
  border: 0;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
}
table caption {
  text-align: left;
  font-size: 1.3em;
  margin: 0.5em 0 0.75em;
}
table thead {
  display: none;
}
table tr {
  display: block;
  /* border: 1px solid #eee; */
  /* padding: 1em 1em 0.5em; */
  @apply rounded-xl;
}
table tr + tr {
  /* margin-top: 0.625em; */
}
table td {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  /* border-bottom: 1px solid #eee; */
  line-height: 1.35em;
}
table td:before {
  content: attr(data-label);
  font-size: 0.9em;
  text-align: left;
  font-weight: bold;
  text-transform: uppercase;
  max-width: 45%;
  color: #545454;
}
table td + td {
  /* margin-top: 0.8em; */
}
table td:last-child {
  border-bottom: 0;
}

@media screen and (min-width: 600px) {
  table caption {
    font-size: 1.5em;
  }
  table thead {
    display: table-header-group;
  }
  table tr {
    display: table-row;
    border: 0;
  }
  table th,
  table td {
    text-align: left;
    @apply overflow-hidden text-ellipsis;
  }
  table th {
    font-size: 0.85em;
    text-transform: uppercase;
  }
  table td {
    display: table-cell;
  }
  table td:before {
    display: none;
  }
  table td:last-child {
    /* border-bottom: 1px solid #eee; */
  }
}

.Toastify__toast-body {
  @apply text-sm;
}

.css-1fdsijx-ValueContainer,
.css-3w2yfm-ValueContainer {
  @apply !p-0 text-sm;
}

::-webkit-scrollbar {
  @apply h-1.5 w-1.5;
}

::-webkit-scrollbar-track {
  @apply rounded-full bg-default-100 shadow-inner;
}

::-webkit-scrollbar-thumb {
  /* Customize the scrollbar thumb */
  @apply rounded-full bg-default-500/50;
}

.MuiDataGrid-root {
  @apply font-dm;
}

.MuiDataGrid-row:nth-child(even) {
  background-color: hsl(var(--nextui-default-50)) !important;
}

.MuiDataGrid-cell {
  @apply text-[12.5px] text-gray-800;
}

.MuiDataGrid-root .MuiDataGrid-cell:focus-within,
.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within {
  outline: none !important;
}

.MuiDataGrid-columnHeaderTitleContainer {
  @apply font-semibold text-gray-700;
}
/* MuiCheckbox-root with aria-disabled true */
.MuiCheckbox-root[aria-disabled='true'] {
  @apply hidden;
}

.MuiDataGrid-row:nth-child(odd):hover {
  background-color: transparent !important;
}

.MuiDataGrid-row:nth-child(even):hover {
  background-color: hsl(var(--nextui-default-50)) !important;
}

*:focus {
  outline: 0;
}
